

export class Global {

 
 
    //// Test Api URL
  //public baseUrl = 'http://test.cambioscope.movie/api/';

  ////Local
  //public baseUrl = 'http://localhost:64254/';
  ////Local
  //public EmbededURL = 'http://localhost:42734/Home';

  //// LIVE
  //public baseUrl = 'https://cambioscope.com/api/';
  ////Live
  //public EmbededURL = 'https://cambioscope.com/Home';



  ////TEST API
  //public baseUrl = 'http://test.cambioscope.com/api/';
  ////Test
  //public EmbededURL = 'http://test.cambioscope.com/Home';


  //Test for brc
  public baseUrl = 'https://barc.cambioscope.com/api/';
  public EmbededURL = 'https://barc.cambioscope.com/Home';


  //////  //Test for madison
  ////public baseUrl = 'https://madison.cambioscope.com/api/';
  ////public EmbededURL = 'https://madison.cambioscope.com/Home';

  ///UserMgt
  public IsLoggedIn: string | null = 'false'
  public IsUserId: string | null = 'false'
  public IsIP: string | null = 'false'

  
  

  //Menu Highlight
  public IsOnMenu: boolean = false
  public IsOnApproachMenu: boolean = false
  public IsOnPlannerMenu: boolean = false
  public IsOnPostEvalMenu: boolean = false
  public IsOnContactUsMenu: boolean = false
  public IsOnStartedMenu: boolean = false
  public IsCinemaStats: boolean = false
  public IsOnPlanner2Menu: boolean = false
  public IsPlannerDAVPMenu: boolean=false

  //
  
  public IsPlannerLogin: string = "false"
  public IsPostEvalLogin: string = "false"
  public IsCineStatsLogin: string = "false"
  public IsPlanner2Login: string = "false"


  public FullName: string="";
  public LastName: string = "";
  public Email: string = "";
  public Phone: string = "";
  public FirstName: string = "";
  public UserType: string = "";
   


    // TEST
        // public baseUrl = 'http://ufoadtrackerapi.diipl.in/api';
        //  public baseLoginUrl = ' http://stagingiroapi.ufomoviez.com/';    
        //http://ufoadtrackerapi.diipl.in/
        // public baseUrl = 'http://ufoadtrackerapi.diipl.in:9443/api';

 
    

}
