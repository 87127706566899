<style>
  li a:hover {
    color: #532ABA;
    text-decoration: underline;
    cursor: pointer;
  }
</style>
<header class="header-style3 bg-transparent border-0">

  <div class="navbar-default sm-bg-white-opacity" style="background-color: #fff;margin-top: -15px;">

    <!-- start top search -->
    <div class="top-search bg-primary">
      <div class="container">
        <form class="search-form" action="search.html" method="GET" accept-charset="utf-8">
          <div class="input-group">
            <span class="input-group-addon cursor-pointer">
              <button class="search-form_submit fas fa-search text-white" type="submit"></button>
            </span>
            <input type="text" class="search-form_input form-control" name="s" autocomplete="off"
                   placeholder="Type &amp; hit enter...">
            <span class="input-group-addon close-search"><i class="fas fa-times display-28 mt-1"></i></span>
          </div>
        </form>
      </div>
    </div>
    <!-- end top search -->

    <div class="container">
      <div class="row align-items-center">
        <div class="col-12">
          <div class="menu_area alt-font">
            <nav class="navbar navbar-expand-lg navbar-light p-0 current">

              <div class="navbar-header navbar-header-custom">
                <!-- start logo -->


                <a class="navbar-brand logo3" style="margin-top: 10px;">
                  <img id="logo" src="../../../../Scripts/assets/img/logos/Cambioscope_logo.svg" (click)="GoToHome()" style="padding-right: 15px;" alt="logo" />                  
                </a>

                <a class="navbar-brand logo31" style="margin-top: 10px;">                  
                  <img id="logo2" src="../../../../Scripts/assets/img/logos/BARC lOGO_VER1.jpg" alt="logo" style="padding-bottom: 6px;" />
                </a>
                <!-- end logo -->
              </div>

              <div class="navbar-toggler"></div>

              <!-- menu area -->
              <!-- MenuH -->
              <ul class="navbar-nav ms-auto" id="nav" style="display: none;">
                <li [ngClass]="{'Menu-Color': this.global.IsOnMenu== true}" style="cursor:pointer;">
                  <a href="#!">Home</a>

                </li>
                <!--<li style="cursor:pointer;">
                  <a [ngClass]="{'Menu-Color': this.global.IsOnApproachMenu== true}" href="#ApproachClick" (click)="ApproachClick()">Approach</a>

                </li>-->

                <li [ngClass]="{'Menu-Color': this.global.IsCinemaStats== true}" style="cursor:pointer;">
                  <a (click)="GoToCinemaStats()">Cine Stats</a>

                </li>
                <li [ngClass]="{'Menu-Color': this.global.IsPlannerDAVPMenu== true}" style="cursor:pointer;">
                  <a (click)="GoToPlannerDAVP()">Planner</a>

                </li>

                <!--<li [ngClass]="{'Menu-Color': this.global.IsOnPlannerMenu== true}" style="cursor:pointer;" *ngIf="IsGeneralUser">
                  <a>Planner</a>
                  <ul class="list-unstyled">
                    <li><a (click)="GotoPlanner()">CPC</a></li>
                    <li><a (click)="GotoPlanner2()">Rack Rates</a></li>
                    <li><a (click)="GotoPlanner1()">Planner 1</a></li>

                  </ul>

                </li>-->
                <!--<li [ngClass]="{'Menu-Color': this.global.IsOnPostEvalMenu== true}" style="cursor:pointer;" *ngIf="IsGeneralUser">
                  <a (click)="GoToPostEval()">
                    Post
                    Eval
                  </a>

                </li>-->


                <li [ngClass]="{'Menu-Color': this.global.IsOnContactUsMenu== true}" style="cursor:pointer;">
                  <a (click)="GotoContact()">Contact Us</a>

                </li>
                <!-- UserMgt -->
                <li [ngClass]="{'Menu-Color': this.global.IsOnStartedMenu== true}" *ngIf="this.global.IsLoggedIn =='false'" style="cursor:pointer;">
                  <a (click)="GotoStartedPage()">Login</a>

                </li>

                <li *ngIf="this.global.IsLoggedIn =='true'" style="cursor:pointer;">
                  <!--<a (click)="LogoutApp()">Logout</a>-->
                  <a>{{FirstName}}</a>
                </li>

                <!--<li>
    <img src="../../../../Scripts/assets/img/content/LoggedIcon.png" style="width: 3%;padding-left: 8px;"
         *ngIf="this.global.IsLoggedIn =='true'" />
  </li>-->
              </ul>
              <div class="attr-nav me-lg-0" style="margin-top: -10px;" *ngIf="this.global.IsLoggedIn =='true'">
                                        <ul>
                                            <li class="dropdown me-3 me-lg-0" style="cursor:pointer;">
                                                <a href="#" class="dropdown-toggle"  id="dropdownMenuLink" data-bs-toggle="dropdown">
                                                    <i class="fa fa-user"></i>
                                                    
                                                </a>
                                                <ul class="dropdown-menu cart-list" style="display: block;">
                                                  <li>
                                                    <!--<a href="#!" class="photo"><img src="img/shop/cart-thumb01.jpg" class="cart-thumb" alt="..."></a>-->
                                                    <!--<h6><a>User Name </a></h6>-->
                                                    <div>
                                                      <h6 style="text-align:center">{{UserFullname}}</h6>
                                                    </div>

                                                    <div class="row">
                                                      <div class="col-12 col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                                        <i class="fa fa-envelope"></i>
                                                      </div>
                                                      <div class="col-12 col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xl-10" style="margin-left: -33px;margin-top: 4px;">
                                                        <p style="text-align:center">
                                                          <span class="usercss">{{EmailID}}</span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="row">
                                                      <div class="col-12 col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                                        <i class="fa fa-phone"></i>
                                                      </div>
                                                      <div class="col-12 col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xl-10" style="margin-left: -64px;margin-top: 4px;">
                                                        <p style="text-align:center">
                                                          <span class="usercss">{{phoneNumber}}</span>
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <!--<p><span class="price">Nitesh</span></p>
                                                    <h6><a>Email ID </a></h6>
                                                    <p><span class="price">Niteshp123@diipl.com</span></p>
                                                    <h6><a>Phone Number </a></h6>
                                                    <p><span class="price">9821517926</span></p>-->
                                                  </li>                                                    
                                                  <li style="background:#532ABA">
                                                    <!--<span class="pull-left"><strong>Total</strong>: $233.31</span>-->
                                                    
                                                    <div class="row">
                                                      <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                                        <button type="button" class="btn btn-secondary" style="background:#fff;color:black;opacity: 1;height: 34px;" (click)="GoforRegistration()">Edit Profile</button>
                                                        <!--<a class="butn small btn-sm" style="color:#fff" (click)="GoforRegistration()"><span>Edit Profile</span></a>-->
                                                      </div>
                                                      <div class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                                        <button type="button" class="btn btn-secondary" style="background:#fff;color:black;opacity: 1;height: 34px;" (click)="LogoutApp()">Logout</button>
                                                        <!--<a (click)="LogoutApp()" class="butn small btn-cart white"><span>Logout</span></a>-->
                                                      </div>
                                                    </div>
                                                  </li>
                                                </ul>
                                            </li>
                                            
                                        </ul>
                </div>

                <!-- end menu area -->
                <!-- start attribute navigation -->
                <!-- end attribute navigation -->

            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>

</header>

<!-- <app-home></app-home>
<app-footer></app-footer> -->
