<html>

<head>
  <style>
    li a:hover {
      color: #532ABA;
      text-decoration: underline;
      cursor: pointer;
    }
  </style>
</head>

<body>
  <footer class="footer-style10">

    <div class="container">
      <div class="row mt-n1-9">

        <div class="col-lg-3 mt-1-9">
          <img src="../../../../Scripts/assets/img/logos/Cambioscope_logo.svg" style="height: 45px" />

        </div>
        <div class="col-sm-6 col-lg-2 mt-1-9">
          <h3>Links</h3>
          <ul class="list-unstyled">
            <!--<li ><a href="#ApproachClick">Approach</a> </li>-->
            <li style="color: #5e6973;"><a (click)="GotoTeam()">Team</a> </li>
            <li style="color: #5e6973;"><a (click)="GotoCaseStudies()">FAQ</a> </li>
          </ul>
        </div>
        <div class="col-sm-6 col-lg-2 mt-1-9">
          <h3>Services</h3>
          <ul class="list-unstyled">
            <!--<li style="color: #5e6973;"><a (click)="GotoPlanner()">Ad Planner</a> </li>
            <li style="color: #5e6973;"><a (click)="GoToPostEval()">Post Eval</a> </li>-->
            <li style="color: #5e6973;"><a (click)="GoToCinemaStats()">Cinema Stats</a> </li>
          </ul>
        </div>
        <div class="col-sm-6 col-lg-2 mt-1-9">
          <h3>About</h3>
          <ul class="list-unstyled">
            <li style="color: #5e6973;"><a (click)="GotoAbout()">About</a> </li>
            <li style="color: #5e6973;"><a (click)="GotoDisclaimer()">Disclaimer</a> </li>
            <li style="color: #5e6973;"><a (click)="GotoPrivacy()">Privacy Policy</a> </li>
          </ul>
        </div>
        <div class="col-sm-6 col-lg-3 mt-1-9">
          <!-- <h3>Newsletter</h3>
          <form class="quform newsletter-form w-sm-90 mx-auto mx-lg-0" action="assets/quform/newsletter-two.php" method="post" enctype="multipart/form-data" onclick="">

            <div class="quform-elements">

              <div class="row">


                <div class="col-md-12">
                  <div class="quform-element">
                    <div class="quform-input">
                      <input class="form-control" id="email_address" type="text" name="email_address" placeholder="Subscribe with us" />
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="quform-submit-inner">
                    <button class="btn btn-white text-primary m-0" type="submit"><i class="fas fa-paper-plane"></i></button>
                  </div>
                  <div class="quform-loading-wrap"><span class="quform-loading"></span></div>
                </div>


              </div>

            </div>

          </form> -->
        </div>

      </div>

    </div>

    <div class="footer-bar">
      <div class="container">
        <div class="row">
          <div class="col-md-6 text-md-start text-center mb-2 mb-md-0">
            <!-- <div class="social-icons">
              <ul class="ps-0">
                <li><a href="#!"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="#!"><i class="fab fa-twitter"></i></a></li>
                <li><a href="#!"><i class="fab fa-instagram"></i></a></li>
                <li><a href="#!"><i class="fab fa-youtube"></i></a></li>
                <li><a href="#!"><i class="fab fa-linkedin-in"></i></a></li>
              </ul>
            </div> -->
          </div>
          <div class="col-md-6 text-md-end text-center">
            <p>
              &copy;
              <span class="current-year"></span> CamBioscope <a href="#!">Dusane Infotech</a>
            </p>
          </div>
        </div>
      </div>
    </div>

  </footer>
</body>

</html>
